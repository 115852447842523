
    const state = {
        authToken: null,
        userName: null
    };
    const mutations = {
        authUser: (state: any, userData: any) => {
            state.authToken = userData.authToken
            state.userName = userData.userName
        },
        clearAuthData: (state: any) => {
            state.authToken = null
            state.userName = null
        }
    };
    const actions = {
        setLogoutTimer: ({commit}: any, expirationTime: any) => {
            setTimeout(() => {
                commit('cleareAuthData')
            }, expirationTime * 1000)
        },
        login: ({commit, dispatch}: any, authData: any) => {
            commit('authUser', {authToken: authData.authToken, userName: authData.userName})
            dispatch('setLogoutTimer', authData.authExpirationTime)
        },
        tryAutoLogin: ({commit}: any, authData: any) => {
            commit('authUser', {authToken: authData.authToken, userName: authData.userName})
        },
        logout: ({commit}: any) => {
            commit('clearAuthData');
        }
    };

    const getters = {
        userName: (state: any) => {
            return state.userName !== null
        },
        isAuthenticated: (state: any) => {
            return state.authToken !== null
          } 
    }

    export default {
        state,
        mutations,
        actions,
        getters
    };