import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Tabs from '../views/Tabs.vue';
import store from '@/store';
import { Config } from '@/shared/config.shared'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/tabs/home'
  },
  {
    path: '/tabs/',
    component: Tabs,
    children: [
      {
        path: '',
        redirect: 'home'
      },
      {
        path: 'home',
        component: () => import('@/views/Home.vue')
      },
      {
        path: 'camera',
        component: () => import('@/views/Camera.vue')
      },
      {
        path: 'tab3',
        component: () => import('@/views/Tab3.vue')
      }
    ]
  },
  {
    path: Config.urlPath.login,
    component: () => import('@/views/account/Login.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = [Config.urlPath.login];
  const authRequired = !publicPages.includes(to.path);
  if (authRequired && !store.getters.isAuthenticated) {
    return next(Config.urlPath.login);
  }
  // Else continue
  next();
})

export default router
